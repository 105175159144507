<template>
    <div>
        <div class="form-list">
            <el-form ref="_sysDicInfoForm" :model="dataSource" :rules="sysDicCheckRule">
                <el-form-item>
                    <el-col :span="3" class="form-title">字典父级：</el-col>
                    <el-col :span="8">
                        <el-form-item>
                            <el-input v-model="dataSource.DictionaryParentName" :maxlength="50" :minlength="4" placeholder="字典父级" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span> 字典编码：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="DictionaryCode">
                            <el-input v-model="dataSource.DictionaryCode" :maxlength="30" :minlength="4" placeholder="字典编码" autofocus="autofocus"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>字典名称：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="DictionaryName">
                            <el-input v-model="dataSource.DictionaryName" :maxlength="30" :minlength="4" placeholder="字典名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">字典序号：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="Sort">
                            <el-input v-model="dataSource.Sort" :maxlength="30" :minlength="4" placeholder="字典序号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            var checkDictionaryName = function (rule, value, callback) {
                if (!value) return callback(new Error('字典名称不能为空'));
                callback();
            };
            return {
                multipleSelection: [],
                isDisabled: false,
                dictionaryParentList: [],
                sysDicCheckRule: {
                    DictionaryName: [{ validator: checkDictionaryName, trigger: 'blur' }]
                },
                dataSource: {},
                
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                dataSourceKey:null,
                dataSource:null
            }
        },
        mounted() {
            this.Event.$on("clearEditSysDicForm", () => this.resetForm);
            this.initialDicTypeList();
            this.initial(this.config.dataSourceKey);
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = this.Utils.emptyGuid != this.dataSource.DictionaryID;
                },
                deep: true
            }
        },
        methods: {
            initial(id){
                if(!id){
                    id = 0;
                }
                var _this = this;
                _this.$ajax.send("omsapi/sysdictionary/getbyid", "get", { id: id }, (data) => {
                    _this.Event.$emit("clearEditSysDicForm");
                    if(data.IsSuccess){
                        _this.dataSource=data.Result;
                        if(!id){
                            _this.dataSource.DictionaryType = 1;
                            _this.dataSource.DictionaryParent = this.config.dataSource.DictionaryParent;
                            _this.dataSource.DictionaryParentName = this.config.dataSource.DictionaryParentName;
                        }
                    }
                });
            },
            save() {
                var _this = this;
                _this.$refs["_sysDicInfoForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        _this.$ajax.send("omsapi/sysdictionary/save", "post", _this.dataSource, (data) => {
                            _this.Event.$emit("reloadSysDicTreeList");
                            _this.Utils.messageBox("保存成功.", "success");
                             return false;
                        });
                    }
                });
            },
            initialDicTypeList(){
                var _this = this;
                _this.$ajax.send("omsapi/base/getlistbyenumname", "get", {name:'DictionaryParentCode'}, (data) => {
                    _this.dictionaryParentList=data.Result;
                });
            }
        },
        components: {
        }
    }

</script>

<style>
</style>